<template>
    <div>
        <hb-notification
            v-model="value"
            v-if="value"
            type="quick-actions"
            @close="close"
        >
            <template v-slot:condensedActions>
                <hb-menu
                    options
                    options-hover-type="quick-actions"
                >
                    <v-list>
                        <v-list-item v-for="(item, index) in actions" :ripple="false" @click="click(item)">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </hb-menu>
            </template>
            <template v-slot:actions>
                <hb-btn v-for="(item, index) in actions" small class="ml-2" :color="item.payload == 'undoCloseOfDay' ? 'secondary' : 'primary'" @click="click(item)">{{ item.title }}</hb-btn>
            </template>
        </hb-notification>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import { EventBus } from '../../EventBus.js'; 
    export default {
        data() {
            return {
                condenseQuickActions: false
            }
        },
        computed:{
            ...mapGetters({
                label: 'snackbarStore/label',
                actions: 'snackbarStore/actions',
                absolute: 'snackbarStore/absolute',
                bottom: 'snackbarStore/bottom',
                color: 'snackbarStore/color',
                left: 'snackbarStore/left',
                multiLine: 'snackbarStore/multiLine',
                right: 'snackbarStore/right',
                timeout: 'snackbarStore/timeout',
                top: 'snackbarStore/top',
                value: 'snackbarStore/value',
                vertical: 'snackbarStore/vertical',
                simple: 'snackbarStore/simple'
            })
        },
        methods: {
            ...mapActions({
                snackbarSetValue: 'snackbarStore/setValue'
            }),
            close(){
                this.$emit('close');
                this.snackbarSetValue(false);
            },
            click(item){
                EventBus.$emit(item.command, item.payload);
            }
        }
    }
</script>
<style scoped lang="scss">
</style>
